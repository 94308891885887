<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-8">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{is_editing ? $t('creator.editDeal') : $t('creator.addDeal')}}</h1>
        <p>{{ $t('creator.addDealDescDesc') }}</p>
      </b-col>
      <div v-if="Object.keys(deal).length > 0">
          <b-form-group aria-describedby="input-live-help invalid-feedback-title" :label="$t('creator.titleDeal')" label-for="input-title" class="form-group-layout">
            <b-form-input :state="stateTitle(deal)" id="input-title" v-model="deal.title" trim @keyup.enter="deal.title.length > 0 ? save(): ''"></b-form-input>
            <b-form-invalid-feedback id="invalid-feedback-title">
              <span v-if="deal.title.length > 100">{{ $t('creator.100charactersDeal') }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="$t('creator.descriptionDeal')" label-for="input-description" class="form-group-layout">
            <b-form-textarea aria-describedby="input-live-help invalid-feedback-description" :state="stateDescription(deal)" id="input-description" v-model="deal.description" rows="3"></b-form-textarea>
            <b-form-invalid-feedback id="invalid-feedback-description">
              <span v-if="deal.description.length > 500">{{ $t('creator.500charactersDeal') }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex">
            <strong class="text-left">{{ $t('creator.feauturesStep') }}</strong>
            <p class="text-muted ml-1">{{ $t('creator.selectStep') }}</p>
          </div>
          <div class="invalid-feedback-deals" v-if="feaures_selected.length === 0">
            {{$t('creator.must_select_feature')}}
          </div>
          <strong class="text-danger col-12 mt-1 h4" v-if="feaures_selected.length === 0"></strong>
          <b-form-group class="mt-1" id="my-btn-group">
            <b-form-checkbox-group
              v-model="feaures_selected"
              buttons
            > 
              <b-form-checkbox v-for="item in $t('creator.features')" :key="item.value"  :value="item.value">
                <template v-slot:default>
                  <div class="d-flex align-items-center">
                    <div class="mr-03">
                      <feather-icon icon="CheckIcon" v-if="feaures_selected.find((i) => i === item.value)"/>
                      <feather-icon icon="PlusIcon" v-else/>
                    </div>
                    <span>
                      {{ item.text }}
                    </span>
                  </div>
                </template>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
          <b-form-group v-if="!is_brandme_talent" :label="$t('creator.priceStep')" label-for="input-price" class="form-group-layout">
            <b-input-group @keyup.enter="deal.price.length > 0 ? save(): ''" prepend="$" id="input-price" :class="!statePayment(deal.price) ? 'is-invalid' : 'is-valid'">
              <component
                :is="cleave"
                v-model="deal.price"
                :options="options_number"
                :class="`form-control ${!statePayment(deal.price) ? 'is-invalid' : 'is-valid'}`"
                :raw="false"
              />
            </b-input-group>
            <div class="invalid-feedback-deals mt-1" v-if="!statePayment(deal.price)">
              {{$t('creator.price_around_50')}}
            </div>
          </b-form-group>
          <b-form-group v-else v-slot="{ ariaDescribedby }">
            <b-form-radio v-model="type_price" :aria-describedby="ariaDescribedby" value="public" stacked class="full-radio">
              <div class="">
                <b-form-group label-for="input-price" class="form-group-layout">
                  <b-input-group @keyup.enter="deal.price.length > 0 ? save(): ''" prepend="$" id="input-price" :class="!statePayment(deal.price) ? 'is-invalid' : 'is-valid'">
                    <component
                      :is="cleave"
                      v-model="deal.price"
                      :options="options_number"
                      :class="`form-control ${!statePayment(deal.price) ? 'is-invalid' : 'is-valid'}`"
                      :raw="false"
                    />
                  </b-input-group>
                  <div class="invalid-feedback-deals mt-1" v-if="!statePayment(deal.price)">
                    {{$t('creator.price_around_50')}}
                  </div>
                </b-form-group>
              </div>
            </b-form-radio>

            <b-form-radio v-model="type_price" :aria-describedby="ariaDescribedby" value="hidden" stacked class="mt-3 full-radio">
              <div class="">
                <b-form-group label-for="input-price" class="form-group-layout w-100">
                  <b-form-rating v-model="range_price" no-border size="lg">
                    <template #icon-empty>
                      <b-img src="@/assets/images/icons/dollar-empty.svg" class="icon-svg"/>
                    </template>
                    <template #icon-full>
                      <b-img src="@/assets/images/icons/dollar-full.svg" class="icon-svg"/>
                    </template>
                  </b-form-rating>
                </b-form-group>
              </div>
            </b-form-radio>
          </b-form-group>
          <b-form-group :label="$t('creator.deliveryStep')" label-for="input-delivery" class="form-group-layout">
            <component :is="vue_select" :options="days_delivery_options" id="input-delivery" v-model="deal.days_delivery" />
          </b-form-group>
          <b-form-group :label="$t('creator.revisionStep')" label-for="input-revision" class="form-group-layout">
            <component :is="vue_select" :options="number_of_revisions_options" id="input-revision" v-model="deal.revision" />
          </b-form-group>
          <b-form-group :label="$t('creator.licensingStep')" label-for="input-licensing" class="form-group-layout">
            <component :is="vue_select" label="text" :options="$t('creator.licensing')" id="input-licensing" v-model="deal_licensing" />
          </b-form-group>
        <b-col class="col-12 d-flex justify-content-center">
          <b-button v-if="is_editing" class="my-2 mr-1" variant="danger" @click="deleteDeal()">{{ $t('creator.delete') }}</b-button>
          <b-button :disabled="!isPassingValidations()" variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()">{{$t('creator.savePreview')}}</b-button>
        </b-col>
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormTextarea,
  BInputGroup,
  BFormInvalidFeedback,
  BFormRadio,
  BFormRating,
  BImg
} from 'bootstrap-vue';
import proposals_services from '@/services/proposals';
import { getUserData } from '../../../../libs/utils/user';

export default {
  name: 'dealsComponent',
  components: {
    BRow,   
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormCheckboxGroup,
    BInputGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormRadio,
    BFormRating,
    BImg
  },
  data() {
    return {
      steps: null,
      options_number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      },
      deal_licensing: null,
      days_delivery_options: [1, 2, 3, 7, 10, 15, 30],
      number_of_revisions_options: [1, 2, 3, 4, 5],
      proposal: {},
      deal: Object.keys(this.deal_selected).length > 0 ? {...this.deal_selected} : {
        price: 335,
        title: this.$t('creator.anyTitle'),
        description: this.$t('creator.anyDescription'),
        features: [],
        days_delivery: 15,
        revision: 2,
        licensing: 'sponsored_social_content'
      },
      feaures_selected: ['share_your_content', 'create_custom_content', 'ship_me_your_product', 'brand_mention', 'product_placement'],
      cleave: null,
      vue_select: null,
      is_brandme_talent: false,
      range_price: null,
      type_price: 'public'
    }
  },
  props: {
    deal_selected: {
      type: Object,
      default: () => {
        return {}
      }
    },
    is_editing: {
      type: Boolean,
      default: false,
    }
  },
  created() {
    this.getData();
    setTimeout(() => {
      this.isPassingValidations()
    }, 2000);
    this.checkBrandmeTalent()
  },
  async mounted() {
    this.cleave = (await import('vue-cleave-component')).default;
    this.vue_select = (await import('vue-select')).default;
  },
  methods: {
    checkBrandmeTalent() {
      const user = getUserData()
      const permissions = JSON.parse(localStorage.getItem('permissions'))
      const has_permission_brandme_talent = permissions.indexOf('brandme_talent')
      this.is_brandme_talent = user.is_superuser || has_permission_brandme_talent > -1
    },
    deleteDeal() {
      proposals_services.deleteElement(this.proposal.uuid, this.deal.uuid, 'deal').then((response) => {
        this.$emit('update_proposal', response)
      });
    },
    stateTitle(deal) {
      if (deal.title) return deal.title.length > 0 && deal.title.length < 100;
      else return false
    },
    stateDescription(deal) {
      if (deal.description) return deal.description.length > 0 && deal.description.length < 500;
      else return false
    },
    statePayment(price) {
      const real_price = typeof price === 'string' ? parseInt(price.replace(/,/g, '')) : price
      return real_price >= 50 && real_price < 9999999
    },
    getData() {
      this.proposal = this.$route.params.proposal;
      this.deal_licensing = Object.keys(this.deal_selected).length > 0 ? this.$t('creator.licensing').find((i) => i.value === this.deal_selected.licensing) : this.$t('creator.licensing')[0]
      if (Object.keys(this.deal_selected).length > 0) this.feaures_selected = this.deal_selected.features.map((i) => i.title)

      if (this.deal_selected.is_brandme_talent && parseFloat(this.deal_selected.price) === 0) {
        this.type_price = 'hidden'
        this.range_price = this.deal_selected.range_price
      }
    },
    save() {
      if (this.isPassingValidations()) {
        const deal_to = Object.assign({}, this.deal)
        if (isNaN(this.deal.price)) deal_to.price = Number(this.deal.price.split(',').join(''));

        deal_to.features = this.feaures_selected;
        deal_to.licensing = this.deal_licensing.value;

        if (this.type_price === 'hidden') {
          deal_to.range_price = this.range_price
          deal_to.is_brandme_talent = false
        } else {
          delete deal_to.range_price
          deal_to.is_brandme_talent = false
        }

        if (this.deal.uuid) {
          proposals_services.updateElement(this.proposal.uuid, deal_to.uuid, deal_to, 'deal').then((response) => {
            this.$emit('update_proposal', response)
          });
        } else {
          proposals_services.addOptionOrRequest(this.proposal.uuid, {deals: [deal_to]}, 'deal').then((response) => {
            this.$emit('update_proposal', response)
          });
        }
      }
    },
    isPassingValidations() {
      const is_passing_all = [];
      is_passing_all.push(this.stateTitle(this.deal))
      is_passing_all.push(this.stateDescription(this.deal))
      is_passing_all.push(this.feaures_selected.length !== 0)
      if (this.type_price === 'public') {
        is_passing_all.push(this.statePayment(this.deal.price))
      } else {
        is_passing_all.push(this.range_price > 0)
      }
      return is_passing_all.every((item) => item === true);
    },
  },
  watch: {
    type_price(new_val) {
      if (new_val === 'public') {
        this.range_price = null;
      }
    },
    range_price() {
      this.type_price = 'hidden'
    }
  }
}
</script>
<style scoped>
.invalid-feedback-deals {
  font-size: 0.857rem !important;
  color: #496daa !important;
}
.mr-03 {
  margin-right: 0.3em;
}
.icon-svg {
  width: 20px;
}
</style>
<style>
.full-radio > .custom-control-label {
  width: 100% !important;
}
#input-delivery .vs__clear,
#input-revision .vs__clear,
#input-licensing .vs__clear {
  display: none;
}
#input-delivery,
#input-revision,
#input-licensing {
  background-color: white;
}
.form-group-layout > label {
  text-align: left;
}
.btn-group-toggle {
  display:initial;
  /* text-align: left; */
}
.btn-group-toggle > label {
  margin: 0 1em 1em 0 !important;
}
#my-btn-group .btn-group-toggle > .btn-secondary {
  /* display: flex !important; */
  width: auto !important;
  border-radius: 2em !important;
  background-color: white !important;
  border: 1px solid #b0b0b0 !important;
  border-right-color: #b0b0b0 !important;
  color: #b0b0b0 !important;
  box-shadow: none !important;
  padding: 0.5em 0.7em;
}
#my-btn-group .btn-group-toggle > .active {
  background-color: #5581f6 !important;
  color: white !important;
  transition: all 300ms;
}

#my-btn-group [dir=ltr] .btn-group > .btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):first-child,
#my-btn-group [dir=ltr] .btn-group > .btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):first-of-type,
#my-btn-group [dir=ltr] .btn-group [class*=btn-]:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]),
#my-btn-group [dir=ltr] .btn-group > .btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):last-child,
#my-btn-group [dir=ltr] .btn-group > .btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):last-of-type 

{
  border-right-color: #b0b0b0 !important;
  border-left-color: #b0b0b0 !important;
}
</style>